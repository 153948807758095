<template>
  <div class="potential">
    <rxNavBar :title="navTitle"></rxNavBar>
    <p class="application_time">申请时间：<span>{{contract.addTime}}</span></p>
    <div class="panel">
      <div class="state">{{contract.approvalStatusStr}}</div>
      <div class="title">
        <p class="van-multi-ellipsis--l2">{{contract.roomDetailedAddress}}</p>
      </div>
      <van-divider />
      <van-row class="info">
        <van-col span="12">
          <p>合同编号：<span>{{contract.contractCode}}</span></p>
        </van-col>
        <van-col span="12">
          <p>收房年限：<span>{{contract.contractYears}}年</span></p>
        </van-col>
        <van-col span="12">
          <p>业主姓名：<span>{{contract.ownerName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>面积：<span>{{contract.obuiltUpArea}}㎡</span></p>
        </van-col>
      </van-row>
    </div>
    <p class="subtitle">申请详情</p>
    <div class="panel">
      <van-row class="info">
        <van-col span="24">
          <p>申请人：<span>{{decorationInfo.applicant}}</span></p>
        </van-col>
        <van-col span="24">
          <p>设计师：<span>{{decorationInfo.designGlobaluserName}}</span></p>
        </van-col>
        <van-col span="12">
          <p>户型改造：<span>{{contract.bedroomCount}}+{{contract.changeCount ? contract.changeCount : '0'}}</span></p>
        </van-col>
        <van-col span="12">
          <p>费用承担：<span>{{decorationInfo.costBearStr}}</span></p>
        </van-col>
      </van-row>
    </div>
    <!-- 图片墙 -->
    <div class="panel withMargin" v-if="imagesList.length != 0">
      <div class="swiper_show" ref="wrapperBox">
        <div class="content">
          <div class="imgWrap" v-for="(url,i) in imagesList" @click="previewImg(i)">
            <van-image width="100" height="75" fit="contain" radius="10" :src="url" />
          </div>
          <div class="num">
            {{imagesList.length}}图
          </div>
        </div>
      </div>
    </div>

    <div class="panel" style="margin-top: 0.5rem">
      <van-tabs v-model="active" line-width="20" line-height="5" title-active-color="rgb(255,81,45)" swipeable sticky>
        <van-tab title="硬装验收">
          <!-- 硬装方案 -->
          <van-row class="info noPadding">
            <van-col span="24">
              <p>硬装负责人：<span>{{decorationInfo.hardChargeName}}</span></p>
            </van-col>
            <van-col span="24">
              <p>实际开始：<span>{{decorationInfo.hardBeginTime}}</span></p>
            </van-col>
            <van-col span="24">
              <div class="overDiv">
                <div>实际结束：</div>
                <span id="">{{decorationInfo.hardEndTime | hardEndTime}}</span>
                <span class="calendar_icon" @click="clickHardTime"></span>
              </div>
              <div class="part-inputpart part-inputpart-dropDownList" v-if="isHardEndTimeShow">
                <van-datetime-picker
                        v-model="hardEndTime"
                        type="date"
                        title="选择年月日"
                        item-height="35px"
                        @confirm="hardExpectedDateConfirm"
                        @cancel="hardExpectedDateCancel"
                />
              </div>
            </van-col>
            <van-col span="24">
              <p>预计合计费用：<span>{{decorationInfo.handBudgetFee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p>实际合计费用：<span class="total_color">{{decorationInfo.hardRealFee}}元</span></p>
            </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="9" class="t_header">项目名称</van-col>
              <van-col span="4" class="t_header">数量</van-col>
              <van-col span="9" class="t_header">实际费用（预计费用）</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="item in hardwareList" :key="item.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title">{{item.goodsAllocationClassName}}</span>
<!--                <van-col span="24" class="t_title">{{item.goodsAllocationClassName}}</van-col>-->
                <van-row v-for="it in item.childrenList" :key="it.id">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="9" class="t_td">{{it.itemName}}</van-col>
                  <van-col span="4" class="t_td_1">{{it.totalCount}}</van-col>
                  <van-col span="9" class="t_td">{{Number(it.realMoney).toFixed(2)}}<span  
                          style="color:rgb(136,136,136) ">（{{Number(it.estimateMoney).toFixed(2)}}）</span></van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
        <van-tab title="软装验收">
          <van-row class="info noPadding">
            <van-col span="24">
              <p>软装负责人：<span>{{decorationInfo.softChargeName}}</span></p>
            </van-col>
            <van-col span="24">
              <p>实际开始：<span>{{decorationInfo.softBeginTime}}</span></p>
            </van-col>
            <van-col span="24" class="">
              <p style="padding: 0">实际结束：
                <span>{{decorationInfo.softEndTime | softEndTime}}</span>
                <span class="calendar_icon" @click="clickSoftTime" style="background-position-y: 0.03rem !important;"></span>
              </p>
              <div class="part-inputpart part-inputpart-dropDownList" v-if="isSoftEndTimeShow">
                <van-datetime-picker
                        v-model="softEndTime"
                        type="date"
                        title="选择年月日"
                        item-height="35px"
                        @confirm="softExpectedDateConfirm"
                        @cancel="softExpectedDateCancel"
                />
              </div>
            </van-col>
            <van-col span="24">
              <p>预计合计费用：<span>{{decorationInfo.softBudgetFee}}元</span></p>
            </van-col>
            <van-col span="24">
              <p>实际合计费用：<span class="total_color">{{decorationInfo.softRealFee}}元</span></p>
            </van-col>
          </van-row>
          <van-divider />
          <div class="t_table">
            <van-row>
              <van-col span="1" class="t_header"></van-col>
              <van-col span="8" class="t_header">项目名称</van-col>
              <van-col span="6" class="t_header">分类</van-col>
              <van-col span="4" class="t_header">数量</van-col>
              <van-col span="4" class="t_header">实际费用</van-col>
              <van-col span="1" class="t_header"></van-col>
            </van-row>
            <div class="t_body" v-for="a in softwareList" :key="a.id">
              <van-row>
                <span class="diamod"></span>
                <span class="t_title">{{a.roomName}}</span>
<!--                <van-col span="24" class="t_title">{{a.roomName}}</van-col>-->
                <van-row v-for="it in a.childrenList" :key="it.id">
                  <van-col span="1" class="t_td"></van-col>
                  <van-col span="8" class="t_td">{{it.itemName}}</van-col>
                  <van-col span="6" class="t_td">{{it.goodsAllocationClassName}}</van-col>
                  <van-col span="4" class="t_td_1">{{it.totalCount}}</van-col>
                  <van-col span="4" class="t_td">{{Number(it.realMoney).toFixed(2)}}元</van-col>
                  <van-col span="1" class="t_td"></van-col>
                </van-row>
              </van-row>
            </div>

          </div>
        </van-tab>
      </van-tabs>
    </div>

    <div class="panel withMargin">
      <van-row>
        <van-col span="24" class="info">
          <p class="allprice">预计总装修款：<span class="floatR">{{Number(decorationInfo.budgetFee).toFixed(2)}}元</span></p>
          <p>实际总装修款：<span class="floatR">{{Number(decorationInfo.totalRealFee).toFixed(2)}}元</span></p>
        </van-col>
      </van-row>
    </div>
    <div v-if="auditOrCheck">
      <div class="panel radio_body withMargin">
        <p style="marginBottom:15px">
          <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
          审核结果
        </p>
        <div class="choose">
          <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
          <div class="line"></div>
          <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
        </div>
      </div>
      <div id="cleanId" v-show="show" class="part-inputpart-row" @click="isCleanTypeShow = !isCleanTypeShow">
        <span :class="!selectCleanType.dictionaryTitle? 'notdot' : 'isdot'" > </span>
        <span class="part-inputpart-row-header">原因</span>
        <span class="divide">|</span>
        <span class="part-inputpart-row-graytext" :class="selectCleanType.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{selectCleanType.dictionaryTitle}}</span>
        <img class="part-inputpart-row-right" :class=" isCleanTypeShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../assets/images/triangle.png">
      </div>

<!--      <div>-->
<!--        <div class="dropDownList" v-if="isCleanTypeShow">-->
<!--          <div  class="part-inputpart-dropDownList-option" :class="item==selectCleanType ? 'optionSelectedColor' : '' " v-for="(item,index) in refuseReasonList" :key="index" @click="selectCleanTypeOption(index,$event)">{{item.dictionaryTitle}}</div>-->
<!--        </div>-->
<!--      </div>-->
      <div  class="panel radio_body withMargin" v-if="gradeShow">
        <p class="fuwu" style="marginBottom:15px">
          <span :class="grade > 0 ? 'dot is_dot' : 'dot'"></span>
          服务人员评价
        </p>
        <div class="choose">
          <van-rate v-model="grade" :size="25" color="rgba(255, 93, 59, 1)" void-icon="star" void-color="#eee"
                    touchable />
        </div>
      </div>

      <div class="panel">
        <p class="yijian">审批意见</p>
        <div class="text_body">
          <van-field v-model="opinion"  type="textarea" class="mes_body" />
        </div>
      </div>
      <div class="sub_btn">
        <!--      <van-button  :class="isChoose || !isCleanTypeSelect  ?'saveButton_Disable':'saveButton_Enable'" :disabled="!(isChoose&&gradeShow) || (isChoose || !isCleanTypeSelect)" size="large" @click="save">保存-->
        <van-button  :class="!isChoose || !grade && !selectCleanType.dictionaryTitle ?'saveButton_Disable':'saveButton_Enable'" :disabled="!isChoose || !grade && !selectCleanType.dictionaryTitle " size="large" @click="save">保存
        </van-button>
      </div>
    </div>
    <van-popup v-model="isCleanTypeShow" position="bottom">
      <van-picker
          show-toolbar
          :columns="refuseReasonList"
          @cancel="isCleanTypeShow = false"
          :default-index="refuseReasonIndex"
          value-key="dictionaryTitle"
          @confirm="selectCleanTypeOption"
      />
    </van-popup>
  </div>
</template>
<script>
  import BScroll from 'better-scroll';
  import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
  import {
    NavBar,
    Tab,
    Tabs,
    Row,
    Col,
    DropdownMenu,
    DropdownItem,
    Tag,
    Button,
    Popup,
    Field,
    Divider,
    Image as VanImage,
    ImagePreview,
    Rate, DatetimePicker, Picker
  } from 'vant'
  import {
    decorationAgree,
    decorationDetailInit,
    decorationRefuse,
    queryEstateRoomDetailList
  } from "../../getData/getData";
  import {responseUtil} from "../../libs/rongxunUtil";
  export default {
    data() {
      return {
        refuseReasonIndex: '',
        //头部标题
        navTitle:'装修验收申请',
        // 硬装日期
        isHardEndTimeShow: false,
        //硬装日期
        hardEndTime: new Date(),
        // 硬装日期
        isSoftEndTimeShow: false,
        //硬装日期
        softEndTime: new Date(),
        //是否隐藏审核部分
        auditOrCheck:true,
        // 服务人员评价显示隐藏
        gradeShow:false,
        istype:2,
        show:false,
        selectCleanType: '请选择',
        isCleanTypeShow: false,
        isCleanTypeSelect: false,
        isEmergency:false,
        isGenerally:false,
        refuseReasonList:[],

        grade: 0,
        decorationInfo: {
        },
        hardwareList: [
        ],
        softwareList: [
        ],
        title: '中海紫金冠地-星海公园-中山路535-1-3号1单元501',
        contract: {
        },
        imagesList: [
        ],
        isChoose: false,
        isPass: null,
        active: 0,
        opinion: '',
      }
    },
    name: 'ReviewOfDecorationScheme',
    components: {
      [NavBar.name]: NavBar,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Row.name]: Row,
      [Col.name]: Col,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [Tag.name]: Tag,
      [Button.name]: Button,
      [Popup.name]: Popup,
      [Field.name]: Field,
      [Divider.name]: Divider,
      [VanImage.name]: VanImage,
      [ImagePreview.Component.name]: ImagePreview.Component,
      [DatetimePicker.name]: DatetimePicker,
      [Rate.name]: Rate,
      [Picker.name]: Picker,
      rxNavBar,
    },
    mounted() {
      this.initData();

    },
    filters: {
      hardEndTime(value) {
        if(null == value || ''==value) return
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        return y+'-'+MM+'-'+d
      },
      softEndTime(value) {
        if(null == value || ''==value) return
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        return y+'-'+MM+'-'+d
      }
    },
    methods: {
      previewImg(start) {
        ImagePreview({
          images: this.imagesList,
          startPosition: start,
          onClose() {
            // do something
          },
        })
      },
      clickCleanTypeShow() {
        this.isCleanTypeShow = !this.isCleanTypeShow;
        var panel = document.getElementById('cleanId')
        document.addEventListener('click', e => {
          if (!panel.contains(e.target)) {
            this.isCleanTypeShow = false
          }
        })
      },
      // 拒绝原因
      selectCleanTypeOption(item,index) {
        this.selectCleanType = item
        this.isCleanTypeShow = false
        this.isCleanTypeSelect = true
        this.refuseId= this.refuseReasonList[index].id
      },
      multipleChoice(isPass) {
        if(isPass == 1){
          this.istype=1;
          // 服务人员评价
          this.gradeShow = true
          this.show = false
        }else {
          this.istype=0;
          this.show = true
          // 服务人员评价
          this.gradeShow = false
        }
        //审核结果
        this.isChoose = true;
        this.isCleanTypeSelect= false;
        this.grade= false;
        this.isPass = isPass
      },
      //装修验收初始化
      initData(){
        let that = this;
        //如果是点击查看进入本页面，则隐藏审核按钮
        if(this.$route.params.afterRentStatus){
          that.auditOrCheck = false
          that.navTitle = '查看详情'
        }
        let initData = {};
        initData.type = '9';
        initData.id = this.$route.params.id
        // initData.id = '332';
        decorationDetailInit(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            console.log(response.data.data);
            that.contract = response.data.data.item
            that.decorationInfo = response.data.data.renovationMap
            that.hardwareList = response.data.data.goodsMap.selectedHardList
            that.softwareList = response.data.data.goodsMap.selectedSoftList
            that.refuseReasonList = response.data.data.refuseReasonList
            // that.imagesList = response.data.data.picList
            let list = response.data.data.picList
            if(list){
              for(let i=0;i<list.length;i++){
                that.imagesList.push(list[i].path)
              }
            }
            that.$nextTick(() => {
              if (!that.scroll) {
                that.scroll = new BScroll(that.$refs.wrapperBox, {
                  scrollX: true,
                  eventPassthrough: 'vertical'
                })
              }
            })
          })
        })
      },
      //硬装日期选择组件 点击确认键后执行方法
      hardExpectedDateConfirm(value){
        var date = new Date(value)
        this.decorationInfo.hardEndTime = date
        //this.houseInfo.expectedDate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()
        this.isHardEndTimeShow = false
      },
      //硬装日期选择组件 点击取消键后执行方法
      hardExpectedDateCancel(){
        this.isHardEndTimeShow = false
      },

      //软装日期选择组件 点击确认键后执行方法
      softExpectedDateConfirm(value){
        var date = new Date(value)
        this.decorationInfo.softEndTime = date
        this.isSoftEndTimeShow = false
      },
      //软装日期选择组件 点击取消键后执行方法
      softExpectedDateCancel(){
        this.isSoftEndTimeShow = false
      },

      //保存
      save(){
        var that = this;
        var func;
        var data = {}
        //通过的接口
        if(that.istype == "1"){
          func = decorationAgree;
          //指导价
          console.log(this.guidingPrice)
          data.pingjia = that.grade
        }
        //拒绝的接口
        if(that.istype == "0"){
          func = decorationRefuse;
          //拒绝理由id
          data.refuseReason_id=that.refuseId;
        }
        data.id = that.contract.id
        data.staff_id = that.contract.approvalApplicantStaff_id
        data.approvalOpinion =that.opinion
        console.log(data.opinion)
        data.rtid =  that.contract.rtid
        data.hardEndTime =  that.hardEndTime
        data.softEndTime =  that.softEndTime
        data.type = "9"
        func(data).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            console.log(response.data)
            responseUtil.alertMsg(that,response.data.msg)
            that.leftReturn();
          })

        })

      },

      //硬装实际结束时间
      clickHardTime() {
        let that = this
        that.isHardEndTimeShow = !that.isHardEndTimeShow;
      },

      //软装实际结束时间
      clickSoftTime() {
        let that = this
        that.isSoftEndTimeShow = !that.isSoftEndTimeShow;
      },


      //返回上一层
      leftReturn(){
        this.$router.go(-1);
      },
    },
  }
</script>
<style lang="less" scoped>
  @redius: 8px;
  @border: 1px solid rgb(244, 244, 244);

  .part-inputpart {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
  }
  .part-inputpart-dropDownList{
    height: 300px;
    /*垂直方向滚动*/
    /*overflow-y: scroll;*/
    overflow: auto;
    width: 99.5%;
    position: absolute;
    z-index: 99;
    margin:4px 0 0 -19px;
  }
  .diamod {
    width: 8px;
    height: 8px;
    background-color: #3B98FF;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 17px 7px 0 18px;
    display: inline-block;
  }

  .t_title {
    background-color: white;
    color:#33ADFF;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    height: 20px;
  }

  .potential {
    width: 375px;
    background-color: rgb(250, 250, 250);
    padding: 0 15px;
    box-sizing: border-box;
    overflow: hidden;
    color: #252525;


    .sub_btn {
      margin: 30px 0 70px;

      .sub_button {
        border-radius: @redius;
        color: rgb(255, 255, 255);
        border: none;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

        &.not_choose {
          background: none;
          background-color: rgba(184, 184, 184, 0.2);
        }
      }
    }

    p {
      margin: 0px;
    }

    .not_choose {
      background: none;
      background-color: rgba(184, 184, 184, 0.2);
    }

    .application_time {
      text-align: right;
      width: 345px;
      font-size: 12px;
      color: rgb(136, 136, 136);
      padding: 2px 0 5px;
    }

    .subtitle {
      padding: 5px 0px;
      color: rgb(244, 99, 76);
      margin-top: 15px;
      font-weight: 700;
      font-size: 12px;
    }
    .overDiv{
      height: 23px;
      /*line-height: 10px;*/
      /*border: 1px solid red;*/
      display: flex;
      align-items: center;
      /*justify-content: center;*/
    }
    /*实际结束更改*/
    .overDiv>div{
      font-weight: bolder;
    }
    .overDiv>span{
      font-size: 13px;
      color: rgb(110,110,110);
    }

    .calendar_icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url(../../assets/images/calendar.png) no-repeat center center;
      background-size: cover;
      margin-left: 10px;
      background-position-y: -1px;
    }

    /*保存按钮不可点击样式*/
    .saveButton_Disable{
      /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
      background-color: rgba(184, 184, 184, 0.2);
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      margin-left: 0;
      text-align: center;
    }
    /*保存按钮可点击样式*/
    .saveButton_Enable{
      background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
      color: white;
      width: 345px;
      height: 50px;
      border-radius: 8px;
      font-size: 18px;
      line-height: 1.22rem;
      text-align: center;
    }
    .dropDownList{
      width: 100%;
      position: absolute;
      z-index: 99;
      margin-top: 50px;
    }
    /*下拉菜单选项样式*/
    .part-inputpart-dropDownList-option{
      text-align: center;
      line-height: 35px;
      background-color: #f5f5f5;
      font-size: 12px;
      border-bottom: 1px white solid;
      color: black;
      float: left;
      width: 345px;
      height: 35px;
      /*position: fixed;*/
      /*z-index: 2001;*/
      /*margin-top: 300px;*/
      /*margin-left: 15px;*/
    }
    .divide {
      font-size: 14px;
      color: #efefef;
      margin:0 7px 0 10px;
    }
    .part-inputpart-row-header{
      font-weight: bold;
      font-size: 14px;
      width: 90px;
      color: black;
    }
    .part-inputpart-row-graytext {
      color: #d9d9d9;
      width: 100%;
      font-size: 14px;
    }
    .part-inputpart-row {
      width: 345px;
      float: left;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: white;
      height: 50px;
      border-radius: 8px;
      margin: 0 5px 15px 0;
    }
    .notdot {
      display: inline-block;
      padding: 3.5px;
      border-radius: 50%;
      background-color: rgb(136, 136, 136);
      vertical-align: middle;
      margin-right: 13px;
      margin-left: 15px;
    }
    .isdot{
      display: inline-block;
      width: 8px;
      height: 6px;
      border-radius: 50%;
      vertical-align: middle;
      margin-right: 13px;
      background-color: rgb(251,91,64);
      margin-left: 15px;
    }
    .optionSelectedColor{
      color: #ff5d3b;
    }
    .part-inputpart-dropDownList-option-selected{
      color: #ff5d3b;
    }
    .part-inputpart-row-normaltext{
      color: black;
    }
    .part-inputpart-row-right-downArrow{
      width: 9px;
      height: 9px;
      margin-right: 15px;
    }
    /*选择三角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow{
      width: 9px;
      height: 9px;
      /*transform: rotateX(180deg);*/
      margin-right: 15px;
    }

    .panel {
      width: 345px;
      background-color: rgb(255, 255, 255);
      border-radius: @redius;
      position: relative;
      font-size: 14px;
      text-align: left;
      overflow: hidden;


      &.withMargin {
        margin: 16px 0;
      }

      &>p {
        padding: 15px 15px 0px;
        font-weight: bolder;
        /*color: #252525;*/

      }

      &>p>span {
        font-weight: normal;
        color: rgb(190, 190, 190);
      }

      .state {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        width: 60px;
        display: block;
        background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
        border-top-left-radius: 16px;
        border-bottom-right-radius: 16px;
        color: rgb(255, 255, 255);
        text-align: center;
        transform: scale(0.5);
        transform-origin: 0% 0%;
        font-family: sans-serif;
        padding: 0 10px;
      }

      .title {
        width: 315px;
        padding: 0 15px;
        font-weight: bolder;
        color: rgb(0, 0, 0);
        font-size: 16px;
        padding-top: 20px;

        span {
          font-size: 12px;
          font-weight: normal;
          color: rgba(153, 153, 153, 1);
        }
      }
      .allprice{
        padding-bottom: 4px;
      }
      /*审核结果,服务人员 审批意见*/
      .shenhe,.fuwu,.yijian{
        font-size: 15px;
      }
      .describe {
        padding-bottom: 15px;
        padding-top: 10px;
        color: rgb(82, 82, 82);
        font-weight: normal;
      }

      .info {
        padding: 15px;
        line-height: 16px;

        &.noPadding {
          padding: 0 15px;
        }

        p {
          margin: 4px 0;
          font-weight: bolder;

          span {
            font-size: 13px;
            font-weight: normal;
            color: rgb(110, 110, 110);
          }
        }

        .floatR {
          float: right;
        }

        .total_color {
          color: rgba(255, 93, 59, 1);
        }
      }

      .text_body {
        padding: 15px;

        .mes_body {
          border-radius: @redius;
          background-color: rgb(250, 250, 250);
        }
      }
    }

    // 单选框
    .radio_body {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: center;

      .dot {
        display: inline-block;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rgb(136, 136, 136);
        vertical-align: middle;
        margin-right: 10px;
        margin-bottom: 6px;

        &.is_dot {
          background-color: rgb(251, 91, 64);
        }
      }

      .line {
        width: 2px;
        height: 12px;
        background-color: rgb(250, 250, 250);
      }

      .choose {
        margin-right: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;

        .choose_btn {
          padding: 5px;
          margin: 10px;
          color: rgb(136, 136, 136);
          cursor: pointer;

          &.pass {
            color: rgb(251, 91, 64);
            font-weight: bolder;
          }

          &.reject {
            color: red;
            font-weight: bolder;
          }
        }
      }
    }

    // 循环表单
    .t_table {
      .t_header {
        text-align: left;
        color: rgb(136, 136, 136);
        /*font-weight: bolder;*/
        font-size: 10px;
        height: 20px;
        line-height: 21px;
        background-color: rgba(243, 249, 255, 1);
      }

      .t_title {
        //background-color: rgb(244, 249, 255);
        color:#33ADFF;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        height: 20px;
      }

      .t_td {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
      }
      .t_td_1 {
        text-align: left;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        padding-left: 10px;
      }
    }

    // 图片墙
    .swiper_show {
      width: 325px;
      position: relative;
      margin: 15px 10px;
      height: 75px;

      .content {
        position: absolute;
        display: flex;
        justify-content: space-around;
        left: 0px;

        .imgWrap {
          margin-right: 10px;
        }
      }

      .num {
        position: absolute;
        left: 4px;
        top: 6px;
        font-size: 10px;
        background-color: rgb(0, 0, 0);
        opacity: .5;
        color: rgb(255, 255, 255);
        padding: 2px 6px;
        display: inline-block;
        border-radius: 10px;
        font-weight: bolder;
        line-height: 10px;
      }
    }
  }
</style>
